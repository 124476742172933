/* Footer styling */
.footer {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 20px 0;
}

.footer p {
    margin: 0;
}

.social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
}

.social-icons a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.social-icons a:hover {
    color: #d1d1d1;
}