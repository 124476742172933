/* Option Button Styles */
.option {
    display: block;
    width: 100%;
    background-color: #F4F4F4;
    color: #333;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.option:hover {
    background-color: #e0e0e0;
}

.option.selected {
    background-color: #0033A0; /* Porsche blue */
    color: #fff;
}

.option.correct {
    background-color: #009900; /* Green for correct */
    color: #fff;
}

.option.incorrect {
    background-color: #D2042D; /* Red for incorrect */
    color: #fff;
}

/* Explanation */
.explanation {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-left: 4px solid #0033A0;
    font-size: 16px;
    text-align: left;
    color: #555;
    border-radius: 5px;
    display: none;
}

.explanation.visible {
    display: block;
}

