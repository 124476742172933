/* General page layout */
.home-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: "Helvetica", sans-serif;
    color: #333;
    background-color: #f4f4f4;
}

/* Hero section styling */
.hero-section {
    flex: 1;
    background: url('../../images/hero-background.png') no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px;
    color: #fff;
}

.hero-content {
    max-width: 600px;
}

.hero-content h2 {
    font-size: 36px;
    margin-bottom: 20px;
}

.hero-content p {
    font-size: 18px;
    margin-bottom: 30px;
}

.cta-button {
    display: inline-block;
    padding: 12px 30px;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    border-radius: 25px;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #333;
}

/* Features section styling */
.features-section {
    display: flex;
    justify-content: space-around;
    padding: 40px 0;
    background-color: #fff;
}

.feature-card {
    max-width: 300px;
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.feature-card h3 {
    font-size: 22px;
    margin-bottom: 10px;
}

.feature-card p {
    font-size: 16px;
}


