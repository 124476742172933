
.slider-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
}

.slider-track {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eaeaea;
    border-radius: 15px;
    width: 300px;
    padding: 5px;
    position: relative;
}

.role-option {
    cursor: pointer;
    color: #888;
    font-weight: normal;
    transition: color 0.3s ease;
}

.role-option.active {
    color: #111;
    font-weight: bold;
}

.slider-thumb {
    position: absolute;
    top: 2px;
    width: 100px;
    height: 36px;
    background-color: #111;
    border-radius: 15px;
    transition: left 0.3s ease;
    z-index: -1;
}