/* Header styling */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #000;
    color: #fff;
}

.logo h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
}

.nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s;
}

.nav ul li a:hover {
    color: #d1d1d1;
}

/* Login and user info section */
.login-info {
    display: flex;
    align-items: center;
}

.user-info {
    display: flex;
    align-items: center;
    gap: 10px;
}

.login-link, .logout-link {
    color: white;
    text-decoration: none;
}

.login-link:hover, .logout-link:hover {
    text-decoration: underline;
}