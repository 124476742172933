/* General container */
.chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    height: 75vh;
    max-width: 90%;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    box-sizing: border-box;
}

/* Chatbox styling */
.chat-box {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 20px;
}

/* Chat message styles */
.chat-message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    max-width: 75%;
    word-wrap: break-word;
    font-size: 16px; /* Default font size */
}





/* Info icon for the tooltip */
.info-icon {
    margin-left: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #007bff;
}

.info-icon:hover {
    color: #0056b3;
}

/* Message text styling */
.message-text {
    display: inline-block;
    max-width: 100%; /* Allow full width */
}

/* Chat input and button container */
.chat-input-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Input field */
.chat-input-box input {
    flex: 1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
    font-size: 16px;
}

/* Send button */
.chat-input-box button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-input-box button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

/* Spinner style */
.spinner {
    font-size: 1.2em;
    animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    /* Adjust container padding for smaller screens */
    .chat-container {
        padding: 10px;
        max-width: 100%;
    }

    /* Adjust font size for smaller screens */
    .chat-message {
        font-size: 14px; /* Smaller font size on mobile */
    }

    /* Reduce margins for messages */
    .chat-message {
        max-width: 85%;
    }

    .user-message {
        margin-left: 0; /* Slightly adjust user message margin */
        align-self: flex-end;
    }

    .bot-message {
        margin-right: 0;
        align-self: flex-start;
    }

    /* Adjust input field and button on mobile */
    .chat-input-box input {
        padding: 8px;
        font-size: 14px;
        margin-right: 5px;
    }

    .chat-input-box button {
        padding: 8px 16px;
        font-size: 14px;
    }

    /* Bot logo size on mobile */
    .bot-logo {
        width: 30px;
        height: 30px;
    }
}
