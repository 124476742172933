.content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    height: 75vh;
    max-width: 90%;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    box-sizing: border-box;
}

.inner-container {
    margin: 0 auto;
    max-width: 90%;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
}

h2 {
    font-size: 24px;
    color: #111;
    margin-bottom: 20px;
}

button {
    background-color: #444;
    border: none;
    color: #ddd;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
    background-color: #555;
    color: #fff;
}

button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

/* Uploaded files list */
ul {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
}

li {
    background-color: #222;
    border: 1px solid #333;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
}

li:hover {
    background-color: #333;
}

li span {
    color: #ccc;
    font-size: 1rem;
}

li button {
    background-color: #e60000; /* Porsche signature red */
    padding: 8px 15px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

li button:hover {
    background-color: #ff3333;
}
