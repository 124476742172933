/* Main container for the chat message */
.chat-message {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* User's message on the right */
.user-message {
    margin-left: auto;
    background-color: #007bff;
    color: white;
    padding: 10px;
    border-radius: 12px 12px 0 12px;
    align-self: flex-end;
}

/* Bot's message on the left */
.bot-message {
    display: flex;
    background-color: #e0e0e0;
    color: black;
    padding: 10px;
    border-radius: 12px 12px 12px 0;
    align-self: flex-start;
}

/* Bot logo */
.bot-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.bot-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Styling for the logo section */
.chat-logo {
    flex-shrink: 0;
    margin-right: 10px;
}

.logo-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

/* Content of the message */
.chat-content {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
}

/* Info icon for the tooltip */
.info-icon {
    margin-left: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #007bff;
}

.info-icon:hover {
    color: #0056b3;
}

/* Tooltip styling */
.tooltip {
    position: absolute;
    top: -10px;
    left: 60px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    white-space: nowrap;
    font-size: 14px;
    color: white;
}


/* Style for the reference list inside the tooltip */
.tooltip ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.tooltip li {
    margin-bottom: 5px; /* Space between list items */
}

/* Tooltip arrow */
.tooltip::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent #ccc transparent transparent;
}
