
/* Upload input and button */
input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #555;
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #222;
    color: #ddd;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.custom-file-upload:hover {
    background-color: #333;
    color: #fff;
}

